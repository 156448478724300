import BaseService from '@/services/BaseService'
import axios from 'axios'
import auth from '@/common/auth'
import utility from '@/common/utility'

let resourceName = 'changeDirectiveItem'
let batchAddUrl = 'BatchAddChangeDirectiveItems'
let syncUrl = 'ChangeDirectivesToMA'

class ChangeDirectiveItemService extends BaseService {
  batchAdd (changeDirectiveGuid, changeDirectiveItems) {
    return axios.post(utility.getFullUrl(this.resourceName, batchAddUrl), changeDirectiveItems, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      },
      params: {
        changeDirectiveGuid
      }
    })
  }

  sync (changeDirectiveGuid) {
    return axios.post(utility.getFullUrl(this.resourceName, syncUrl), null, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      },
      params: {
        changeDirectiveGuid
      }
    })
  }
}

const changeDirectiveItemService = new ChangeDirectiveItemService(resourceName)

export default changeDirectiveItemService
