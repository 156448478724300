const ChangeDirectiveItemSpreadInfo = {
  sheetName: '变更明细',
  columns: [
    {
      label: '排序字段',
      dataKey: 'sortId',
      editable: true,
      width: '100'
    },
    {
      label: '清单编号',
      dataKey: 'meterageId',
      editable: true,
      width: '100'
    },
    {
      label: '清单父节点编号',
      dataKey: 'parentId',
      editable: true,
      width: '130'
    },
    {
      label: '章节',
      dataKey: 'chapter',
      editable: true,
      width: '100'
    },
    {
      label: '子目号',
      dataKey: 'meterageCode',
      editable: true,
      width: '150'
    },
    {
      label: '子目号（展示）',
      dataKey: 'meterageDisplayCode',
      editable: true,
      width: '150'
    },
    {
      label: '子目名称',
      dataKey: 'meterageName',
      editable: true,
      width: '180'
    },
    {
      label: '单位',
      dataKey: 'unit',
      editable: true,
      width: '120'
    },
    {
      label: '单价',
      dataKey: 'price',
      editable: true,
      width: '150'
    },
    {
      label: '数量',
      dataKey: 'num',
      editable: true,
      width: '150'
    },
    {
      label: '金额',
      dataKey: 'amount',
      editable: true,
      width: '150'
    },
    {
      label: '工程部位',
      dataKey: 'part',
      editable: true,
      width: '200'
    },
    {
      label: '数量保留位数',
      dataKey: 'numDigits',
      editable: true,
      width: '100'
    },
    {
      label: '单价保留位数',
      dataKey: 'priceDigits',
      editable: true,
      width: '100'
    },
    {
      label: '金额保留位数',
      dataKey: 'amountDigits',
      editable: true,
      width: '100'
    }
  ]
}

export default ChangeDirectiveItemSpreadInfo
