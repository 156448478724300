import utility from '@/common/utility'
import auth from '@/common/auth'

export default class ChangeDirectiveItemModel {
  constructor (props) {
    this.resourceId = props.changeDirectiveItemGuid
    this.changeDirectiveItemGuid = props.changeDirectiveItemGuid
    this.changeDirectiveGuid = props.changeDirectiveGuid
    this.meterageId = props.meterageId
    this.parentId = props.parentId
    this.meterageCode = props.meterageCode
    this.meterageDisplayCode = props.meterageDisplayCode
    this.meterageName = props.meterageName
    this.unit = props.unit
    this.part = props.part
    this.num = props.num
    this.price = props.price
    this.amount = props.amount
    this.staffName = props.staffName
    this.sortId = props.sortId
    this.createUserGuid = props.createUserGuid
    this.createTime = props.createTime
    this.chapter = props.chapter
    this.numDigits = props.numDigits
    this.priceDigits = props.priceDigits
    this.amountDigits = props.amountDigits
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.changeDirectiveItemGuid) this.changeDirectiveItemGuid = ''
    if (!this.changeDirectiveGuid) this.changeDirectiveGuid = ''
    if (!this.meterageId) this.meterageId = 0
    if (!this.parentId) this.parentId = 0
    if (!this.meterageCode) this.meterageCode = ''
    if (!this.meterageDisplayCode) this.meterageDisplayCode = ''
    if (!this.meterageName) this.meterageName = ''
    if (!this.unit) this.unit = ''
    if (!this.num) this.num = 0
    if (!this.price) this.price = 0
    if (!this.amount) this.amount = 0
    if (!this.staffName) this.staffName = ''
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
    if (!this.chapter) this.chapter = 0
    if (!this.numDigits) this.numDigits = 0
    if (!this.priceDigits) this.priceDigits = 0
    if (!this.amountDigits) this.amountDigits = 0
  }

  generatePrimaryKey () {
    this.changeDirectiveItemGuid = utility.getUuid()
    this.resourceId = this.changeDirectiveItemGuid
  }
}
