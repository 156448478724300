<template>
  <div class="change-directive-item-detail">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-button @click="handleSave">保存</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <div class="spread-wrapper">
      <my-spread
        ref="mySpread"
        :spread-info="spreadInfo"
        :default-data-list="[]"
        >
      </my-spread>
    </div>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import changeDirectiveItemSpreadInfo from '@/views/ChangeDirectiveManage/ChangeDirectiveItemSpreadInfo'
import MySpread from '@/components/Spread/MySpreadOld'
import ChangeDirectiveItemModel from '@/model/ChangeDirectiveItemModel'
import changeDirectiveItemService from '@/services/changeDirectiveItemService'
import utility from '@/common/utility'
import auth from '@/common/auth'

export default {
  name: 'ChangeDirectiveItemDetail',
  components: {
    MySearchBar,
    MySpread
  },
  data () {
    return {
      changeDirectiveGuid: this.$route.query.changeDirectiveGuid,
      spreadInfo: changeDirectiveItemSpreadInfo,
      changeDirectiveItems: []
    }
  },
  methods: {
    handleSave () {
      this.$refs.mySpread.validate()
      if (this.$refs.mySpread.errorKeys.length === 0) {
        this.$myLoading()
        let data = this.$refs.mySpread.getUsefullData().map(item => {
          let model = new ChangeDirectiveItemModel(item)
          model.createUserGuid = auth.getUserInfo().userGuid
          model.changeDirectiveItemGuid = utility.getUuid()
          model.changeDirectiveGuid = this.changeDirectiveGuid
          return model
        })
        console.log('change-item-data', data)

        changeDirectiveItemService.batchAdd(this.changeDirectiveGuid, data)
          .then(res => {
            this.$loading().close()
            if (res.data.code === 1) {
              this.$message({
                type: 'success',
                message: '操作成功！'
              })
              this.$router.push(`/changeDirectiveItem?changeDirectiveGuid=${this.changeDirectiveGuid}`)
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              })
            }
          })
          .catch(err => {
            console.log(err)
            this.$loading().close()
            this.$message({
              type: 'error',
              message: '操作失败，请重试！'
            })
          })
      } else {
        this.$message({
          type: 'error',
          message: '校验失败，请检查您的输入！'
        })
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.$refs.mySpread.worksheet.getCell(-1, 4).formatter('@')
      this.$refs.mySpread.worksheet.getCell(-1, 5).formatter('@')
    }, 500)
  }
}
</script>

<style scoped lang="scss">
.change-directive-item-detail {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 70px 1fr;
}
</style>
